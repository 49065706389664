import React, { useState } from "react";
import {
  convertToUTCString,
  parseURL,
  sortRows,
} from "../../../../../utils/general";
import {
  GetControlReportStats,
  GetControlReportTable,
} from "../../../../../services/regulation-policy/overview";
import PageLayout from "../../../../../layouts/PageLayout";
import { motion } from "framer-motion";
import {
  attributeColors,
  initialSort,
  showVariants,
} from "../../../../../constants/general";
import ReturnPage from "../../../../../components/Button/ReturnPage";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableLayout from "src/layouts/TableLayout";
import SortColumn from "src/components/Button/SortColumn";
import { ListHeader } from "../../../../../types/general";
import { Switch } from "@headlessui/react";
import ExportFile from "./ExportFile";

const RowValue = ({ row, col }: { row; col }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const content = String(row[col.property_name]);
  const longText = content?.length > 100;

  return (
    <p
      className={`text-left ${attributeColors[String(content)?.toLowerCase()]}`}
    >
      {content?.slice(0, showMore || !longText ? content.length : 100)}{" "}
      {longText && (
        <p className="dark:text-checkbox dark:hover:text-checkbox/60 duration-100">
          ...
          <button onClick={() => setShowMore(!showMore)}>
            show {showMore ? "less" : "more"}
          </button>{" "}
        </p>
      )}
    </p>
  );
};

const CoverageReport = () => {
  const parsed = parseURL();

  const [sort, setSort] = useState(initialSort);
  const [isCoverage, setIsCoverage] = useState<boolean>(true);

  const reportType = String(parsed.report_type) || "";
  const frameworkID = String(parsed.report_id) || "";
  const frameworkName = String(parsed.report_name) || "";
  const coverage = isCoverage
    ? Number(parsed.coverage)
    : (100 - Number(parsed.coverage)).toFixed(2) || 0;
  const type = isCoverage ? "coverage" : "gaps";

  const { data: controlStats } = GetControlReportStats(frameworkID, type);
  const { data: controlTable } = GetControlReportTable(frameworkID, type);

  const sortedTable = sortRows(controlTable?.data, sort);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="grid content-start gap-5 p-4 w-full h-full text-sm overflow-auto scrollbar"
      >
        <header className="grid gap-5">
          <article className="flex items-center gap-5">
            <ReturnPage />
            <h4 className="text-lg capitalize">
              Overview <FontAwesomeIcon icon={faArrowRightLong} />{" "}
              {reportType.replaceAll("_", " ")}
            </h4>
          </article>
          <h2 className="text-2xl">{frameworkName}</h2>
        </header>
        <article className="flex flex-col flex-grow gap-5 mx-auto text-center text-2xl">
          <article className="flex items-center gap-2 mx-auto">
            <span className="text-coverage">Coverage</span>
            <Switch
              checked={isCoverage}
              onChange={setIsCoverage}
              className={`${
                isCoverage ? "bg-coverage" : "bg-gray-500"
              } relative inline-flex h-6 w-11 items-center rounded-sm`}
            >
              <span
                className={`${
                  isCoverage ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-sm bg-white transition`}
              />
            </Switch>
            <span className="text-gray-500">Gaps</span>
          </article>
          <CircularProgressbarWithChildren
            strokeWidth={10}
            value={coverage}
            maxValue={100}
            styles={buildStyles({
              trailColor: "#FFF",
              pathColor: "#fcba03",
            })}
          >
            <span>{coverage}%</span>
          </CircularProgressbarWithChildren>
        </article>
        {controlStats && (
          <section className="flex flex-row flex-grow gap-10 mx-auto">
            {Object.keys(controlStats).map((key) => {
              if (Object.keys(controlStats[key]).length === 0) return null;
              return (
                <article
                  key={key}
                  className="grid content-start gap-2 p-2 w-full dark:bg-gray-600 rounded-md"
                >
                  <h4 className="text-center text-xl capitalize font-bold">
                    {key}
                  </h4>
                  <ul className="grid grid-cols-2 content-start gap-x-5 gap-y-2 text-base">
                    {Object.entries(controlStats[key]).map((keyVal) => {
                      const pct =
                        (keyVal[1][isCoverage ? "mapped" : "unmapped"] /
                          keyVal[1].total) *
                        100;
                      return (
                        <li
                          key={keyVal[0]}
                          className={`px-2 py-1 ${
                            pct >= 66.6
                              ? "dark:bg-no"
                              : pct >= 33.3
                              ? "dark:text-black dark:bg-yellow-500"
                              : "dark:bg-reset/30"
                          } rounded-md`}
                        >
                          {keyVal[0]}:{" "}
                          {keyVal[1][isCoverage ? "mapped" : "unmapped"]} /{" "}
                          {keyVal[1].total}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              );
            })}
          </section>
        )}

        <section className="grid gap-2 text-base">
          <header className="flex items-center justify-between gap-10">
            <h4>Control Table</h4>
            <ExportFile documentID={frameworkID} type={type} />
          </header>
          {controlTable ? (
            sortedTable?.length > 0 ? (
              <TableLayout fullHeight>
                <thead className="sticky -top-1.5 dark:bg-gray-900">
                  <tr>
                    {controlTable.header.map((col: ListHeader) => {
                      return (
                        <th
                          scope="col"
                          key={col.display_name}
                          className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                        >
                          <article className="capitalize flex gap-10 justify-between">
                            <h4>{col.display_name}</h4>
                            <SortColumn
                              propertyName={col.property_name}
                              setSort={setSort}
                            />
                          </article>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {sortedTable?.map((row: any, rowIndex: number) => {
                    return (
                      <tr
                        key={rowIndex}
                        className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                      >
                        {controlTable.header.map(
                          (col: ListHeader, index: number) => {
                            return (
                              <td
                                key={index}
                                className="relative px-6 py-3 last:pr-16 text-left break-words"
                              >
                                {col.data_type === "timestamp" ? (
                                  convertToUTCString(row[col.property_name])
                                ) : col.data_type === "list" ? (
                                  <ul className="grid list-disc px-4">
                                    {row[col.property_name].map(
                                      (value: string) => (
                                        <li key={value}>{value}</li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  <RowValue row={row} col={col} />
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </TableLayout>
            ) : (
              <p>No data available</p>
            )
          ) : null}
        </section>
      </motion.main>
    </PageLayout>
  );
};

export default CoverageReport;
