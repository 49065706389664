/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import GlobalQuestions from "./GlobalQuestions/GlobalQuestions";
import CustomQuestionSets from "./CustomQuestionSets/CustomQuestionSets";
import AskGlobalQuestion from "./GlobalQuestions/AskGlobalQuestion";
import NewQuestionSet from "./GlobalQuestions/NewGlobalQuestionSet";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import { useGRCStore } from "src/stores/grc";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchResults from "../../SearchResults";

const Questions = ({
  selectedTab,
  setSelectedTab,
  selectedQuestions,
  setSelectedQuestions,
  search,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
  selectedQuestions: string[];
  setSelectedQuestions: (selectedQuestions: string[]) => void;
  search: any;
}) => {
  const { GRCQuery, setGRCQuery, GRCQueryOption } = useGRCStore();

  const type = selectedTab;

  const handleSearch = () => {
    if (GRCQuery && type && GRCQueryOption) {
      search.reset();
      search.mutate(
        {
          query: GRCQuery,
          type: type,
          searchType: GRCQueryOption,
        },
        {
          onSuccess: () => {
            sessionStorage.GRCCategory = "Third Party Risk";
          },
        }
      );
    }
  };

  useEffect(() => {
    if (GRCQuery === "") search.reset();
  }, [GRCQuery]);

  useEffect(() => {
    setSelectedTab("all questions");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex flex-wrap items-center justify-between gap-10">
        <nav className="flex flex-wrap items-center">
          {["all questions", "question sets"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "all questions" && (
          <article className="flex flex-wrap items-center gap-5">
            <article className="flex items-center px-4 dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
              <span className="dark:text-white">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </span>
              <input
                type="input"
                autoComplete="off"
                spellCheck="false"
                placeholder="Search"
                value={GRCQuery}
                onKeyUpCapture={(e) => {
                  if (e.key === "Enter" && GRCQuery !== "") handleSearch();
                }}
                onChange={(e) => setGRCQuery(e.target.value)}
                className="p-4 pr-12 w-[30rem] h-8 dark:bg-gray-800 focus:outline-none"
              />
              {GRCQuery !== "" && (
                <article className="flex items-center gap-2 divide-x dark:divide-checkbox">
                  <button
                    className="dark:hover:text-blue-500 duration-100"
                    onClick={handleSearch}
                  >
                    Enter
                  </button>
                  <button
                    data-test="clear-query"
                    className="pl-2 trash-button"
                    onClick={() => {
                      setGRCQuery("");
                      search.reset();
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </article>
              )}
            </article>
            <AskGlobalQuestion />
            <NewQuestionSet />
          </article>
        )}
      </header>

      {search.status === "success" ? (
        <SearchResults
          handleSearch={handleSearch}
          searchData={search.data}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          selectedTab={selectedTab}
        />
      ) : selectedTab === "all questions" ? (
        <GlobalQuestions
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      ) : (
        <CustomQuestionSets />
      )}
    </section>
  );
};

export default Questions;
