import React from "react";
import {
  GetEvidenceAnalysis,
  StartEvidenceAnalysis,
} from "../../../../../services/audit-management";
import { faCheck, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Analysis = ({
  auditID,
  controlID,
}: {
  auditID: string;
  controlID: string;
}) => {
  const startAnalysis = StartEvidenceAnalysis(auditID, controlID);
  const {
    data: analysis,
    status: analysisStatus,
    refetch,
  } = GetEvidenceAnalysis(auditID, controlID);

  const completed = analysis?.response;

  return (
    <section className="flex flex-col flex-grow gap-2 pt-5">
      {completed ? (
        <article className="flex items-center gap-5">
          <p>
            <FontAwesomeIcon icon={faCheck} className="text-yellow-500" />{" "}
            Analysis Completed
          </p>
          <button
            className="flex items-center gap-1 dark:hover:text-no/60 duration-100"
            onClick={() => startAnalysis.mutate({})}
          >
            <FontAwesomeIcon icon={faRefresh} className="text-no" />
            <h4>Re-run Analysis</h4>
          </button>
        </article>
      ) : (
        <button
          disabled={startAnalysis.status === "success" && !completed}
          className="blue-button"
          onClick={() => startAnalysis.mutate({})}
        >
          <h4>Start Analysis</h4>
        </button>
      )}
      <section className="flex flex-col flex-grow gap-2">
        {analysisStatus === "success" ? (
          analysis ? (
            <section className="grid gap-2">
              <h4>Analysis</h4>
              <ReactMarkdown
                className="min-w-full p-2 dark:text-black dark:bg-white prose overflow-auto rounded-md"
                remarkPlugins={[remarkGfm]}
              >
                {analysis.response}
              </ReactMarkdown>
            </section>
          ) : (
            <article className="flex flex-wrap items-center gap-5">
              Analysis not available yet{" "}
              <button
                className="flex items-center gap-1 dark:hover:text-no/60 duration-100"
                onClick={() => refetch()}
              >
                <FontAwesomeIcon icon={faRefresh} className="text-no" />
                Refresh
              </button>
            </article>
          )
        ) : null}
      </section>
    </section>
  );
};

export default Analysis;
