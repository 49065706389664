/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const DocumentSectionFilter = ({
  inputs,
  setInputs,
  list,
}: {
  inputs: KeyStringVal;
  setInputs: (inputs: KeyStringVal) => void;
  list: KeyStringVal[];
}) => {
  const value = inputs.source_section_title
    ? `${
        !["", null, "-"].includes(inputs.source_section_id)
          ? inputs.source_section_id
          : ""
      } ${inputs.source_section_title}`
    : "";
  return (
    <DropdownLayout
      label={`Document ${inputs.selected_tab}`}
      value={value}
      width="w-[30rem]"
      placeholder="Please select"
    >
      {list?.map((section: KeyStringVal, index: number) => (
        <button
          key={index}
          className={`relative flex items-start gap-2 px-4 py-1 w-full break-words text-left dark:hover:bg-gray-600/70 ${
            inputs.source_generated_id === section.generated_id
              ? "dark:bg-filter"
              : ""
          } duration-100`}
          onClick={() =>
            setInputs({
              ...inputs,
              section_id: section.section_id,
              source_section_id: section.sub_section_id,
              source_section_title: section.sub_section_title,
              source_section_generated_id: section.generated_id,
              content: section.content,
            })
          }
        >
          <p>
            {!["", null, "-"].includes(section.sub_section_id) &&
              section.sub_section_id}{" "}
            {section.sub_section_title}
          </p>
        </button>
      ))}
    </DropdownLayout>
  );
};

export default DocumentSectionFilter;
