/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import TablePagination from "../../../components/General/TablePagination";
import { pageSize } from "../../../constants/general";
import {
  GetFrameworkNames,
  GetControlFamilies,
  GenerateNewPolicy,
  GetPolicyGroups,
  AddPolicyGroup,
  GenerateForm,
} from "../../../services/regulation-policy/policy";
import { KeyStringVal } from "../../../types/general";
import ModalLayout from "src/layouts/ModalLayout";

const GeneratePolicy = ({
  selectedPolicyGroup,
  setSelectedPolicyGroup,
  setShowGeneratePolicy,
}: {
  selectedPolicyGroup: any;
  setSelectedPolicyGroup: any;
  setShowGeneratePolicy: (showGeneratedPolicy: boolean) => void;
}) => {
  const [nav, setNav] = useState<number>(1);
  const [inputs, setInputs] = useState<any>({
    framework_names: [],
    control_families: {},
    form_data: [],
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [groupID, setGroupID] = useState<string>("");
  const [selectedControlFamily, setSelectedControlFamily] =
    useState<string>("");
  const [editedForm, setEditedForm] = useState<boolean>(false);

  const { data: frameworks } = GetFrameworkNames(pageNumber);
  const { data: controlFamilies } = GetControlFamilies(inputs.framework_names);
  const generatePolicy = GenerateNewPolicy();
  const { data: policyGroups } = GetPolicyGroups();
  const addPolicyGroup = AddPolicyGroup();
  const generateForm = GenerateForm();

  const totalCount = frameworks?.length || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const generatedGroup = policyGroups?.find(
    (policyGroup: KeyStringVal) => policyGroup.title === "Generated-Policies"
  );
  const controlFamilyNames = [
    ...new Set(
      controlFamilies?.reduce((pV, cV) => [...pV, cV.control_family], [])
    ),
  ] as string[];
  const subControls = controlFamilies?.reduce((pV, cV) => {
    if (cV.control_family === selectedControlFamily)
      return [...pV, cV.sub_control];
    return [...pV];
  }, []);

  const handleOnClose = () => setShowGeneratePolicy(false);
  const handleOnCloseConfirmation = () => generatePolicy.reset();
  const handleClear = () =>
    setInputs({
      ...inputs,
      control_families: {
        ...inputs.control_families,
        [selectedControlFamily]: [],
      },
    });
  const handleReset = () => {
    setNav(1);
    setInputs({
      framework_names: [],
      control_families: {},
      form_data: [],
    });
    setShowGeneratePolicy(false);
  };

  useEffect(() => {
    if (selectedControlFamily === "" && controlFamilyNames?.length > 0)
      setSelectedControlFamily(controlFamilyNames[0]);
  }, [controlFamilyNames]);

  useEffect(() => {
    const policyGroup = policyGroups?.find(
      (group: KeyStringVal) =>
        group.policy_group_id === sessionStorage.selectedPolicyGroupID
    );
    if (policyGroup) sessionStorage.selectedPolicyGroupName = policyGroup.title;
  }, [policyGroups]);

  return (
    <>
      <section className="grid content-start gap-5">
        {nav === 3 && inputs.form_data.length > 0 ? (
          <section className="flex flex-col flex-grow gap-5 p-4 w-full h-full bg-gradient-to-r dark:from-admin/70 dark:to-white/10 rounded-md">
            <article className="flex items-center justify-center gap-5 mx-auto">
              <button
                className="flex items-center justify-self-center gap-2 px-4 py-2 w-max dark:text-white gradient-button rounded-sm"
                onClick={() => {
                  setNav(2);
                  setInputs({
                    ...inputs,
                    control_families: {},
                    form_data: [],
                  });
                  setSelectedControlFamily(controlFamilyNames[0]);
                  setEditedForm(false);
                }}
              >
                <h4>Prev</h4>
              </button>
              <button
                disabled={!editedForm}
                className="place-self-center blue-button"
                onClick={() => {
                  if (!generatedGroup)
                    addPolicyGroup.mutate(
                      {
                        title: "Generated-Policies",
                        description: "Uno generated Policies",
                      },
                      {
                        onSuccess: (data) => {
                          setGroupID(data.policy_group_id);
                          generatePolicy.mutate({
                            frameworkNames: inputs.framework_names,
                            controlFamilies: inputs.control_families,
                            policyGroupID: data.policy_group_id,
                            formData: inputs.form_data,
                          });
                          handleReset();
                        },
                      }
                    );
                  else {
                    generatePolicy.mutate({
                      frameworkNames: inputs.framework_names,
                      controlFamilies: inputs.control_families,
                      policyGroupID: generatedGroup?.policy_group_id,
                      formData: inputs.form_data,
                    });
                    handleReset();
                  }
                }}
              >
                <h4>Generate</h4>
              </button>
            </article>
            <h3 className="text-2xl">Company Specific Information</h3>
            <section className="flex flex-col flex-grow gap-3">
              {inputs.form_data.map((object) => {
                const controlFamily = Object.keys(object)[0];
                return (
                  <article
                    key={controlFamily}
                    className="flex flex-col flex-grow gap-3"
                  >
                    <h4 className="text-xl font-bold">{controlFamily}</h4>
                    <ul className="flex flex-col flex-grow gap-5">
                      {Object.keys(object[controlFamily]).map((subControl) => {
                        const fields = object[controlFamily][subControl];
                        return (
                          <li
                            key={subControl}
                            className="flex flex-col flex-grow gap-3 p-4 bg-gradient-to-r dark:from-admin/70 dark:to-white/10 rounded-md"
                          >
                            <h4 className="text-lg font-medium">
                              {subControl}
                            </h4>
                            <article className="flex flex-col flex-grow gap-7">
                              {fields?.map((field, fieldIndex) => {
                                return (
                                  <article
                                    key={`${field.display_key}-${fieldIndex}`}
                                  >
                                    <header className="flex items-center gap-5 w-full">
                                      <h4>{field.display_key}</h4>
                                      <article className="relative group">
                                        <FontAwesomeIcon
                                          icon={faInfoCircle}
                                          className="cursor-pointer"
                                        />
                                        <article className="hidden group-hover:grid absolute left-0 bottom-7 gap-2 px-2 py-1 w-[40rem] dark:bg-gray-900 rounded">
                                          <h4 className="text-b1-reg">
                                            Control Statement
                                          </h4>
                                          <p className="text-b2-reg">
                                            {field.control_statement}
                                          </p>
                                        </article>
                                      </article>
                                    </header>
                                    <input
                                      type="input"
                                      value={field.value}
                                      spellCheck="false"
                                      autoComplete="off"
                                      onChange={(e) => {
                                        if (!editedForm) setEditedForm(true);
                                        setInputs({
                                          ...inputs,
                                          form_data: inputs.form_data.map(
                                            (curObject) => {
                                              if (
                                                Object.keys(curObject)[0] ===
                                                controlFamily
                                              ) {
                                                return {
                                                  [controlFamily]:
                                                    Object.fromEntries(
                                                      Object.entries(
                                                        curObject[controlFamily]
                                                      ).map((keyVal) => {
                                                        if (
                                                          keyVal[0] ===
                                                          subControl
                                                        ) {
                                                          return [
                                                            keyVal[0],
                                                            keyVal[1].map(
                                                              (curField) => {
                                                                if (
                                                                  curField.display_key ===
                                                                  field.display_key
                                                                )
                                                                  return {
                                                                    ...curField,
                                                                    value:
                                                                      e.target
                                                                        .value,
                                                                  };
                                                                return curField;
                                                              }
                                                            ),
                                                          ];
                                                        }
                                                        return [
                                                          keyVal[0],
                                                          keyVal[1],
                                                        ];
                                                      })
                                                    ),
                                                };
                                              }
                                              return curObject;
                                            }
                                          ),
                                        });
                                      }}
                                      className="py-1 px-4 w-full h-10 focus:outline-none placeholder:italic placeholder:text-xs dark:placeholder:text-checkbox dark:disabled:text-checkbox dark:bg-panel dark:border-transparent dark:focus:ring-0 dark:focus:border-transparent"
                                    />
                                  </article>
                                );
                              })}
                            </article>
                          </li>
                        );
                      })}
                    </ul>
                  </article>
                );
              })}
            </section>
          </section>
        ) : nav === 2 ? (
          <>
            <section className="grid gap-3">
              <article className="flex items-center justify-center gap-5">
                <button
                  className="flex items-center justify-self-center gap-2 px-4 py-2 w-max dark:text-white gradient-button rounded-sm"
                  onClick={() => {
                    setNav(1);
                    setInputs({
                      ...inputs,
                      control_families: {},
                    });
                  }}
                >
                  <h4>Prev</h4>
                </button>
                <button
                  disabled={Object.keys(inputs.control_families).length === 0}
                  className="blue-button"
                  onClick={() => {
                    if (!generatedGroup)
                      addPolicyGroup.mutate(
                        {
                          title: "Generated-Policies",
                          description: "Uno generated Policies",
                        },
                        {
                          onSuccess: (data) => {
                            setGroupID(data.policy_group_id);
                            generateForm.mutate(
                              {
                                controlFamilies: inputs.control_families,
                                policyGroupID: data.policy_group_id,
                              },
                              {
                                onSuccess: (data) => {
                                  if (
                                    data?.length > 0 &&
                                    Object.keys(data[0]).length > 0
                                  ) {
                                    setNav(3);
                                    setInputs({ ...inputs, form_data: data });
                                  } else {
                                    generatePolicy.mutate({
                                      frameworkNames: inputs.framework_names,
                                      controlFamilies: inputs.control_families,
                                      policyGroupID: data.policy_group_id,
                                      formData: inputs.form_data,
                                    });
                                    handleReset();
                                  }
                                },
                              }
                            );
                          },
                        }
                      );
                    else {
                      generateForm.mutate(
                        {
                          controlFamilies: inputs.control_families,
                          policyGroupID: generatedGroup?.policy_group_id,
                        },
                        {
                          onSuccess: (data) => {
                            if (
                              data?.length > 0 &&
                              Object.keys(data[0]).length > 0
                            ) {
                              setNav(3);
                              setInputs({ ...inputs, form_data: data });
                              setSelectedControlFamily(data);
                            } else {
                              generatePolicy.mutate({
                                frameworkNames: inputs.framework_names,
                                controlFamilies: inputs.control_families,
                                policyGroupID: generatedGroup?.policy_group_id,
                                formData: inputs.form_data,
                              });
                              handleReset();
                            }
                          },
                        }
                      );
                    }
                  }}
                >
                  Generate
                </button>
              </article>
              <section className="flex items-start gap-10">
                {controlFamilyNames ? (
                  controlFamilyNames.length > 0 ? (
                    <section className="flex flex-col flex-grow gap-2">
                      <h4 className="mx-auto text-lg w-max border-b-1 dark:border-yellow-500">
                        Control Families
                      </h4>
                      <ul className="grid gap-2 w-full">
                        {controlFamilyNames?.map((controlFamily: string) => {
                          const selected =
                            selectedControlFamily === controlFamily;
                          return (
                            <article
                              key={controlFamily}
                              className={`flex items-center gap-2 p-4 w-full text-center cursor-pointer break-words bg-gradient-to-r dark:from-yellow-500/70 dark:to-yellow-500/10 dark:hover:to-white/30 rounded-md ${
                                selected ? "border-2 dark:border-blue-600" : ""
                              }`}
                              onClick={() =>
                                setSelectedControlFamily(controlFamily)
                              }
                            >
                              <p>{controlFamily}</p>
                            </article>
                          );
                        })}
                      </ul>
                    </section>
                  ) : (
                    <p>No control families found</p>
                  )
                ) : null}
                {selectedControlFamily !== "" && (
                  <article className="grid gap-2 w-full">
                    <article className="flex items-center gap-5 divide-x dark:divide-checkbox">
                      <button
                        disabled={
                          inputs.control_families[selectedControlFamily]
                            ?.length === subControls?.length
                        }
                        className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                        onClick={() =>
                          setInputs({
                            ...inputs,
                            control_families: {
                              ...inputs.control_families,
                              [selectedControlFamily]: subControls,
                            },
                          })
                        }
                      >
                        Select All
                      </button>
                      <button
                        disabled={
                          inputs.control_families[selectedControlFamily]
                            ?.length === 0
                        }
                        className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                        onClick={handleClear}
                      >
                        Deselect All
                      </button>
                    </article>
                    <h4>Sub Controls</h4>
                    <article className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-5 gap-5">
                      {subControls?.map((subControl) => {
                        const subControlSelected =
                          inputs.control_families[
                            selectedControlFamily
                          ]?.includes(subControl);

                        const isGenerated = controlFamilies?.find(
                          (family) => family.sub_control === subControl
                        )?.generated;
                        return (
                          <button
                            key={subControl}
                            disabled={isGenerated}
                            className={`grid gap-1 p-3 text-center ${
                              !isGenerated
                                ? subControlSelected
                                  ? "bg-gradient-to-r dark:from-admin/70 dark:to-white/10 dark:border-yellow-500 dark:hover:to-white/30"
                                  : "bg-gradient-to-r dark:from-checkbox/60 dark:hover:to-white/30"
                                : ""
                            } dark:disabled:text-filter/50 dark:disabled:bg-gray-500 dark:disabled:border-black/30 border-l-4 rounded-md`}
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!subControlSelected)
                                setInputs({
                                  ...inputs,
                                  control_families: {
                                    ...inputs.control_families,
                                    [selectedControlFamily]: [
                                      ...(inputs.control_families[
                                        selectedControlFamily
                                      ] || []),
                                      subControl,
                                    ],
                                  },
                                });
                              else {
                                const newSubControls = inputs.control_families[
                                  selectedControlFamily
                                ].filter(
                                  (control: string) => control !== subControl
                                );
                                const noControlSelected = newSubControls.every(
                                  (curControl) =>
                                    !subControls.includes(curControl)
                                );

                                if (noControlSelected) {
                                  const newControlFamilies =
                                    inputs.control_families;
                                  delete newControlFamilies[
                                    selectedControlFamily
                                  ];
                                  setInputs({
                                    ...inputs,
                                    control_families: newControlFamilies,
                                  });
                                } else
                                  setInputs({
                                    ...inputs,
                                    control_families: {
                                      ...inputs.control_families,
                                      [selectedControlFamily]: newSubControls,
                                    },
                                  });
                              }
                            }}
                          >
                            {isGenerated && (
                              <span className="px-3 py-1 text-white bg-no/30 rounded-md">
                                Generated
                              </span>
                            )}
                            <p className="mx-auto">{subControl}</p>
                          </button>
                        );
                      })}
                    </article>
                  </article>
                )}
              </section>
            </section>
          </>
        ) : (
          <section className="grid gap-3">
            <button
              disabled={inputs.framework_names.length === 0}
              className="mx-auto blue-button"
              onClick={() => setNav(2)}
            >
              <h4>Next</h4>
            </button>
            <h4 className="mx-auto text-lg w-max border-b-1 dark:border-yellow-500">
              Frameworks
            </h4>
            {inputs.framework_names.length === 0 && (
              <span className="flex items-center gap-2 mx-auto">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="dark:text-event"
                />
                Please select at least one framework
              </span>
            )}
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            <ul className="flex flex-col flex-grow gap-3 pb-4 w-full h-full">
              {frameworks?.map((framework: KeyStringVal, index: number) => {
                return (
                  <li
                    key={index}
                    className={`flex items-start justify-between gap-20 p-4 cursor-pointer bg-gradient-to-r dark:from-checkbox/70 dark:to-white/10 dark:hover:to-white/30 rounded-md ${
                      inputs.framework_names.includes(framework.name)
                        ? "border-2 dark:border-blue-600"
                        : ""
                    }`}
                    onClick={() => {
                      if (!inputs.framework_names.includes(framework.name))
                        setInputs({
                          ...inputs,
                          framework_names: [
                            ...inputs.framework_names,
                            framework.name,
                          ],
                        });
                      else
                        setInputs({
                          ...inputs,
                          framework_names: inputs.framework_names.filter(
                            (name: string) => name !== framework.name
                          ),
                        });
                    }}
                  >
                    <h4 className="font-medium">{framework.name}</h4>
                  </li>
                );
              })}
            </ul>
          </section>
        )}
      </section>
      <ModalLayout
        showModal={generatePolicy.status === "success"}
        onClose={handleOnCloseConfirmation}
      >
        <section className="grid content-start gap-10 h-full">
          <article className="flex items-center gap-2">
            <img
              src="/general/checkmark.svg"
              alt="checkmark"
              className="w-5 h-5"
            />
            <h3 className="text-subt1-semi">
              Policy generation has begun. GRC AI Agents are on it! Will notify
              you as soon as the document is generated by the autonomous agent.
            </h3>
          </article>
          <article className="flex items-center gap-2">
            <h4>
              Go to Policy
              <button
                className="p-1 dark:hover:bg-gray-700/70 duration-100 rounded-full"
                onClick={() => {
                  setSelectedPolicyGroup(!selectedPolicyGroup);
                  sessionStorage.selectedPolicyGroupID =
                    generatedGroup?.policy_group_id || groupID;
                  handleOnCloseConfirmation();
                  handleOnClose();
                }}
              >
                <FontAwesomeIcon icon={faArrowRightLong} />
              </button>
            </h4>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default GeneratePolicy;
