import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { EditVendor } from "src/services/third-party-risk/vendors/vendors";
import UserInput from "../../../../components/Input/UserInput";
import { EditVendorLogo } from "../../../../services/third-party-risk/vendors/vendors";
import { queryClient } from "src/App";
import LogoInput from "src/components/Input/LogoInput";
import CategoryInput from "../../../../components/Input/CategoryInput";

const UpdateVendor = ({
  vendorID,
  vendor,
}: {
  vendorID: string;
  vendor: any;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: vendor.name,
    last_updated: vendor.last_updated,
    inherent_risk: vendor.inherent_risk,
    address: vendor.address,
    contact_name: vendor.contact_name,
    contact_email: vendor.contact_email,
    contact_phone: vendor.contact_phone,
    number_of_employees: vendor.number_of_employees,
    vendor_type: vendor.vendor_type,
    vendor_manager: vendor.vendor_manager,
    vendor_tier: vendor.vendor_tier,
    rank_tier: vendor.rank_tier,
    website: vendor.website,
    duns_number: vendor.duns_number,
    status: vendor.status,
    logo: vendor.logo,
    is_publicly_traded: vendor.is_publicly_traded,
    notes: vendor.notes,
  });
  const [valid, setValid] = useState<boolean>(true);

  const editVendor = EditVendor(vendorID);
  const editVendorLogo = EditVendorLogo();

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="px-4 py-2 text-b1-reg dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-xl"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Vendor</h3>
          <section className="flex items-center gap-10 w-full">
            <LogoInput
              label="Logo"
              keyName="logo"
              types={["jpeg", "png", "svg"]}
              inputs={inputs}
              setInputs={setInputs}
            />
            <section className="grid md:grid-cols-2 gap-5 w-full">
              <RegularInput
                label="Vendor"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                valid={valid}
                setValid={setValid}
                required
              />
              <RegularInput
                label="Vendor Type"
                keyName="vendor_type"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Contact Name"
                keyName="contact_name"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Contact Email"
                keyName="contact_email"
                inputs={inputs}
                setInputs={setInputs}
              />
            </section>
          </section>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Contact Phone"
              keyName="contact_phone"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Address"
              keyName="address"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Website"
              keyName="website"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="DUNS Number"
              keyName="duns_number"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Number of Employees"
              keyName="number_of_employees"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Vendor Manager"
              keyName="vendor_manager"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["active", "inactive"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Inherent Risk"
              keyName="inherent_risk"
              list={["critical", "high", "medium", "low"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Vendor Tier"
              keyName="vendor_tier"
              list={["critical", "high", "medium", "low"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
            <CategoryInput
              label="Rank Tier"
              keyName="rank_tier"
              list={["critical", "high", "medium", "low"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
              vertical
            />
          </section>
          <article className="flex items-center gap-2 text-b2-reg">
            <input
              type="checkbox"
              checked={inputs.is_publicly_traded}
              className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-600 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50"
              onChange={() => {
                setInputs({
                  ...inputs,
                  is_publicly_traded: !inputs.is_publicly_traded,
                });
              }}
            />
            <label>Publicly traded</label>
          </article>
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === "" || inputs.inherent_risk === ""}
              className="blue-button"
              onClick={() => {
                editVendor.mutate(
                  {
                    info: {
                      name: inputs.name,
                      inherent_risk: inputs.inherent_risk,
                      address: inputs.address,
                      contact_name: inputs.contact_name,
                      contact_email: inputs.contact_email,
                      contact_phone: inputs.contact_phone,
                      vendor_type: inputs.vendor_type,
                      vendor_manager: inputs.vendor_manager,
                      vendor_tier: inputs.vendor_tier,
                      rank_tier: inputs.rank_tier,
                      website: inputs.website,
                      duns_number: inputs.duns_number,
                      number_of_employees: inputs.number_of_employees,
                      status: inputs.status,
                      is_publicly_traded: inputs.is_publicly_traded,
                      notes: inputs.notes,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries(["get-vendor-metadata"]);

                      if (inputs.logo) {
                        const formData = new FormData();
                        formData.append("file", inputs.logo);
                        editVendorLogo.mutate({
                          vendorID: vendorID,
                          formData: formData,
                        });
                      }
                    },
                  }
                );
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateVendor;
