import React from "react";
import ReturnPage from "src/components/Button/ReturnPage";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CycleMetadata from "./CycleMetadata";
import VendorList from "./VendorList";

const AssessmentCycle = () => {
  const parsed = parseURL();

  const assessmentID = String(parsed.assessment_id) || "";

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-5 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <article className="flex items-center gap-2">
          <ReturnPage />
          <h4 className="flex items-center gap-2 text-b3-reg">
            <span className="dark:text-gray-500">Third Party Risk</span>{" "}
            <span>/</span>
            <span>Assessment</span>
          </h4>
        </article>
        <CycleMetadata assessmentID={assessmentID} />
        <VendorList assessmentID={assessmentID} />
      </main>
    </PageLayout>
  );
};

export default AssessmentCycle;
