/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GetVendorDocuments } from "../../../../../../services/third-party-risk/vendors/vendors";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import {
  faChevronCircleDown,
  faChevronCircleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../../../../components/Loader/Loader";
import NewDocuments from "./NewDocuments";
import { Disclosure } from "@headlessui/react";
import VendorResponseList from "./VendorResponseList/VendorResponseList";
import DeleteDocument from "./DeleteDocument";
import AuditReportList from "./AuditReportList/AuditReportList";
import PrivacyDocumentList from "./PrivacyDocumentList/PrivacyDocumentList";
import { attributeColors } from "../../../../../../constants/general";
import OtherDocumentList from "./OtherDocumentList";

const DocumentList = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string;
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");

  const { data: documentList, status: documentListStatus } = GetVendorDocuments(
    vendorID,
    assessmentID
  );

  const documentTypes = [
    ...new Set(documentList?.reduce((pV, cV) => [...pV, cV.document_type], [])),
  ] as string[];

  const filteredDocumentList =
    selectedDocumentType !== ""
      ? documentList?.filter(
          (document) => document.document_type === selectedDocumentType
        )
      : [];

  useEffect(() => {
    if (documentTypes?.length > 0 && selectedDocumentType === "")
      setSelectedDocumentType(documentTypes[0]);
  }, [documentTypes]);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <section className="flex flex-row flex-grow gap-10 md:gap-20 w-full overflow-auto scrollbar">
        {documentTypes?.length > 0 && (
          <nav className="flex flex-row flex-grow pb-2 text-b1-reg w-3/5 overflow-auto scrollbar">
            {[...documentTypes].map((documentType) => {
              return (
                <button
                  key={documentType}
                  className={`px-8 py-2 text-center capitalize ${
                    selectedDocumentType === documentType
                      ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                      : "dark:text-gray-500 dark:hover:text-white"
                  }`}
                  onClick={() => setSelectedDocumentType(documentType)}
                >
                  {documentType}
                </button>
              );
            })}
          </nav>
        )}
        {documentList?.length > 0 && (
          <NewDocuments vendorID={vendorID} assessmentID={assessmentID} />
        )}
      </section>
      {documentListStatus === "loading" ? (
        <Loader />
      ) : selectedDocumentType === "Audit Reports" ? (
        <AuditReportList vendorID={vendorID} assessmentID={assessmentID} />
      ) : selectedDocumentType === "Privacy Documents" ? (
        <PrivacyDocumentList vendorID={vendorID} assessmentID={assessmentID} />
      ) : filteredDocumentList?.length > 0 ? (
        <OtherDocumentList
          vendorID={vendorID}
          assessmentID={assessmentID}
          filteredDocumentList={filteredDocumentList}
        />
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/policies-placeholder.svg"
            alt="reviews placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-xl font-extrabold">Documents</h4>
            <h4>No documents available</h4>
            <NewDocuments vendorID={vendorID} assessmentID={assessmentID} />
          </article>
        </section>
      )}
    </section>
  );
};

export default DocumentList;
