import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faUpload } from "@fortawesome/free-solid-svg-icons";
import { AddAssessment } from "src/services/questionnaire-rfp";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import Tags from "./Tags";
import { KeyStringVal } from "src/types/general";
import { useGRCStore } from "src/stores/grc";
import { decodeJWT } from "src/utils/general";

const NewAssessment = ({
  selectedSourceType,
}: {
  selectedSourceType: string;
}) => {
  const jwt = decodeJWT();

  const { setSelectedGRCAssessment } = useGRCStore();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    requesting_party: "",
    owner: "",
    reviewer: "",
    due_date: Date.now() * 1000,
    tags: [],
    source_type: selectedSourceType,
    enable_sources: true,
    enable_questionbank: true,
  });

  const addAssessment = AddAssessment();

  const handleOnClose = () => {
    setShow(false);
    addAssessment.reset();
  };
  const handleOnCloseConfirmation = () => addAssessment.reset();

  return (
    <>
      <button
        className="mx-auto blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            requesting_party: "",
            owner: "",
            reviewer: "",
            due_date: Date.now() * 1000,
            tags: [],
            source_type: selectedSourceType,
            enable_sources: true,
            enable_questionbank: true,
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Start Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <>
          {addAssessment.status === "success" ? (
            <section className="grid content-start gap-10 h-full">
              <article className="flex items-center gap-2">
                <img
                  src="/general/checkmark.svg"
                  alt="checkmark"
                  className="w-5 h-5"
                />
                <h3 className="text-subt1-semi">
                  New Assessment has been created!
                </h3>
              </article>
              <article className="flex items-center gap-2">
                <h4>
                  Go to Assessment
                  <button
                    className="p-1 dark:hover:bg-gray-700/70 duration-100 rounded-full"
                    onClick={() => {
                      setSelectedGRCAssessment(addAssessment.data);
                      sessionStorage.assessment_id =
                        addAssessment.data.assessment_id;
                      sessionStorage.assessment_name = addAssessment.data.name;
                      handleOnCloseConfirmation();
                      handleOnClose();
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </button>
                </h4>
              </article>
            </section>
          ) : (
            <section className="grid content-start gap-5 h-full">
              <h3 className="flex items-center gap-2 text-t1-semi">
                Start Assessment
              </h3>
              <article className="grid lg:grid-cols-2 gap-10">
                <RegularInput
                  label="Name"
                  keyName="name"
                  inputs={inputs}
                  setInputs={setInputs}
                  required
                />
                <RegularInput
                  label="Requesting Party"
                  keyName="requesting_party"
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <GeneralSnapshotDatepicker
                  label="Due Date"
                  keyName="due_date"
                  inputs={inputs}
                  setInputs={setInputs}
                  onlyFutureDate
                />
              </article>
              <article className="flex items-center gap-5 text-b2-reg">
                <h4>Must select at least one </h4>
                <article className="flex items-center gap-1 w-max">
                  <input
                    type="checkbox"
                    checked={inputs.enable_sources}
                    onChange={() =>
                      setInputs({
                        ...inputs,
                        enable_sources: !inputs.enable_sources,
                      })
                    }
                    className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-500 dark:focus:border-blue-500 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
                  />
                  <label htmlFor="">Enable Source</label>
                </article>
                <article className="flex items-center gap-1 w-max">
                  <input
                    type="checkbox"
                    checked={inputs.enable_questionbank}
                    onChange={() =>
                      setInputs({
                        ...inputs,
                        enable_questionbank: !inputs.enable_questionbank,
                      })
                    }
                    className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-500 dark:focus:border-blue-500 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
                  />
                  <label htmlFor="">Enable Question Bank</label>
                </article>
              </article>
              <Tags inputs={inputs} setInputs={setInputs} />
              <article className="flex items-center place-content-end gap-5">
                <button className="black-button" onClick={handleOnClose}>
                  Cancel
                </button>
                <button
                  disabled={
                    inputs.name === "" ||
                    (!inputs.enable_questionbank && !inputs.enable_sources)
                  }
                  className="blue-button"
                  onClick={() => {
                    addAssessment.mutate({
                      info: {
                        name: inputs.name,
                        requesting_party: inputs.requesting_party,
                        due_date: inputs.due_date,
                        owner: jwt?.sub,
                        reviewer: null,
                        tags: inputs.tags.reduce(
                          (pV: string[], cV: KeyStringVal) => [
                            ...pV,
                            cV.tag_id,
                          ],
                          []
                        ),
                        source_type: inputs.source_type,
                        enable_sources: inputs.enable_sources,
                        enable_questionbank: inputs.enable_questionbank,
                      },
                    });
                    handleOnClose();
                  }}
                >
                  Done
                </button>
              </article>
            </section>
          )}
        </>
      </ModalLayout>
    </>
  );
};

export default NewAssessment;
