/* eslint-disable no-restricted-globals */
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { showVariants } from "../../../../constants/general";
import { KeyStringVal } from "../../../../types/general";
import { sortNumericData } from "../../../../utils/general";
import { GetAllReports } from "../../../../services/regulation-policy/overview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const Summaries: React.FC = () => {
  const navigate = useNavigate();

  const { data: reports } = GetAllReports();

  return (
    <motion.main
      variants={showVariants}
      initial="hidden"
      animate="visible"
      className="flex flex-col flex-grow gap-5 w-full h-full dark:text-white font-light"
    >
      {reports && (
        <section className="flex flex-col flex-grow gap-5 pb-20">
          {Object.keys(reports).map((companyName) => {
            return (
              <section key={companyName} className="grid gap-2">
                <h4>{companyName}</h4>
                <section className="grid grid-cols-1 md:grid-cols-2 items-stretch gap-5 pb-4">
                  {Object.keys(reports[companyName])?.map(
                    (reportType: string) => {
                      const filteredReports = sortNumericData(
                        reports[companyName][reportType],
                        "coverage",
                        "desc"
                      );
                      return (
                        <section
                          key={reportType}
                          className="grid content-start gap-3 p-4 dark:bg-panel black-shadow"
                        >
                          <header className="flex items-center gap-2 capitalize">
                            <FontAwesomeIcon icon={faStar} />
                            <h4>{reportType.replaceAll("_", " ")}</h4>
                          </header>
                          {filteredReports?.length > 0 ? (
                            <ul className="grid content-start dark:text-checkbox">
                              {filteredReports?.map((report: KeyStringVal) => {
                                return (
                                  <button
                                    key={report.id}
                                    className="flex items-center justify-between gap-10 p-2 text-left disabled:cursor-auto disabled:dark:text-filter/60 disabled:dark:bg-expand/40 cursor-pointer dark:hover:bg-gray-600/70 duration-100 border-b-1 dark:border-checkbox/30"
                                    onClick={() =>
                                      navigate(
                                        `/regulation-policy/report/details?report_type=${reportType}&report_name=${report.name}&report_id=${report.id}&coverage=${report.coverage}`
                                      )
                                    }
                                  >
                                    <h4>{report.name}</h4>
                                    <span>{report.coverage}%</span>
                                  </button>
                                );
                              })}
                            </ul>
                          ) : (
                            <p className="dark:text-checkbox">No reports</p>
                          )}
                        </section>
                      );
                    }
                  )}
                </section>
              </section>
            );
          })}
        </section>
      )}
    </motion.main>
  );
};

export default Summaries;
