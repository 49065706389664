import React from "react";
import { convertToUTCShortString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetVendorMetadata } from "src/services/third-party-risk/vendors/vendors";
import UpdateVendor from "../UpdateVendor";
import ExportResponses from "./DocumentList/VendorResponseList/ExportResponses";
import VendorTags from "../VendorTags";
import { GetAllUsers } from "src/services/settings/users";
import { getCustomerID } from "../../../../../utils/general";
import VendorGroupFilter from "../../../../../components/Filter/ThirdPartyRisk/VendorGroupFilter";
import Status from "./Status";
import SendEmail from "./SendEmail";

const VendorMetadata = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string | undefined;
}) => {
  const customerID = getCustomerID();

  const hasAssessment = localStorage.assessmentCycle && assessmentID;

  const { data: vendorMetadata } = GetVendorMetadata(vendorID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  const vendorManager = allUsers?.find(
    (user) => user.user_id === vendorMetadata?.vendor_manager
  )?.user_email;

  return (
    <>
      {vendorMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            {hasAssessment && (
              <h4>Assessment: {localStorage.assessmentCycle}</h4>
            )}
            <header className="flex items-start justify-between gap-5">
              <article className="grid content-start gap-1">
                <article className="flex items-center gap-5">
                  {vendorMetadata.logo_uri && (
                    <img
                      src={vendorMetadata.logo_uri}
                      alt="logo"
                      className="w-10 h-10 rounded-full"
                    />
                  )}
                  <h2 className="text-subt1-semi">{vendorMetadata.name}</h2>
                  <VendorGroupFilter
                    label="Vendor Group"
                    vendor={vendorMetadata}
                  />
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>last update at</h4>
                  <span>
                    {convertToUTCShortString(vendorMetadata.last_updated)}
                  </span>
                </article>
              </article>
              <article className="flex items-center gap-5">
                {hasAssessment && (
                  <SendEmail
                    vendorID={vendorID}
                    assessmentID={assessmentID}
                    vendor={vendorMetadata}
                  />
                )}
                <Status vendorID={vendorID} active={vendorMetadata.active} />
                <ExportResponses
                  vendorID={vendorID}
                  assessmentID={assessmentID}
                />
                <UpdateVendor vendorID={vendorID} vendor={vendorMetadata} />
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              {vendorMetadata.is_publicly_traded && (
                <span className="px-2 py-1 dark:bg-blue-600 rounded">
                  Publicly Traded
                </span>
              )}
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[vendorMetadata.active ? "active" : "inactive"]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[
                      vendorMetadata.active ? "active" : "inactive"
                    ]
                  }`}
                >
                  {vendorMetadata.active ? "active" : "inactive"}
                </span>
              </article>
              {vendorMetadata.inherent_risk && (
                <article
                  className={`capitalize ${
                    attributeColors[vendorMetadata.inherent_risk?.toLowerCase()]
                  }`}
                >
                  Inherent Risk{" "}
                  <span
                    className={`${
                      attributeTextColors[vendorMetadata.inherent_risk]
                    }`}
                  >
                    {vendorMetadata.inherent_risk}
                  </span>
                </article>
              )}
              {vendorMetadata.vendor_type && (
                <article
                  className={`flex items-center gap-1 ${
                    attributeColors[String(vendorMetadata.vendor_tier)]
                  }`}
                >
                  Vendor Tier
                  <span
                    className={`${
                      attributeTextColors[String(vendorMetadata.vendor_tier)]
                    }`}
                  >
                    {vendorMetadata.vendor_tier}
                  </span>
                </article>
              )}
              {vendorMetadata.rank_tier && (
                <article
                  className={`flex items-center gap-1 ${
                    attributeColors[String(vendorMetadata.rank_tier)]
                  }`}
                >
                  Rank Tier
                  <span
                    className={`${
                      attributeTextColors[String(vendorMetadata.rank_tier)]
                    }`}
                  >
                    {vendorMetadata.rank_tier}
                  </span>
                </article>
              )}
            </section>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {vendorMetadata.vendor_type && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Vendor Type
                  </h4>
                  <span className="text-b1-semi">
                    {vendorMetadata.vendor_type}
                  </span>
                </article>
              )}
              {vendorMetadata.contact_name && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Contact Name
                  </h4>
                  <span className="text-b1-semi">
                    {vendorMetadata.contact_name}
                  </span>
                </article>
              )}
              {vendorMetadata.contact_email && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Contact Email
                  </h4>
                  <span className="text-b1-semi">
                    {vendorMetadata.contact_email}
                  </span>
                </article>
              )}
              {vendorMetadata.contact_phone && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Contact Phone
                  </h4>
                  <span className="text-b1-semi">
                    {vendorMetadata.contact_phone}
                  </span>
                </article>
              )}
              {vendorMetadata.address && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Address</h4>
                  <span className="text-b1-semi">{vendorMetadata.address}</span>
                </article>
              )}
              {vendorMetadata.website && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Website</h4>
                  <span className="text-b1-semi">{vendorMetadata.website}</span>
                </article>
              )}
              {vendorMetadata.duns_number && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    DUNS Number
                  </h4>
                  <span className="text-b1-semi">
                    {vendorMetadata.duns_number}
                  </span>
                </article>
              )}
              {vendorMetadata.number_of_employees > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Number of Employees
                  </h4>
                  <span className="text-b1-semi">
                    {vendorMetadata.number_of_employees}
                  </span>
                </article>
              )}
              {vendorManager && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Vendor Manager
                  </h4>
                  <span className="text-b1-semi">{vendorManager}</span>
                </article>
              )}
            </section>
          </section>
          <section className="p-6 dark:bg-gray-800 rounded-2xl">
            <VendorTags vendorID={vendorID} tags={vendorMetadata.tags} />
          </section>
        </header>
      )}
    </>
  );
};

export default VendorMetadata;
