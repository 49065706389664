/* eslint-disable react-hooks/exhaustive-deps */

const Columns = ({
  label,
  headers,
  sheet,
  markedAsQuestionCol,
  setMarkedAsQuestionCol,
  markedAsAnswerCol,
  setMarkedAsAnswerCol,
  ignoreResponse,
}: {
  label: string;
  headers: any;
  sheet: any;
  markedAsQuestionCol: any;
  setMarkedAsQuestionCol: any;
  markedAsAnswerCol: any;
  setMarkedAsAnswerCol: any;
  ignoreResponse?: boolean;
}) => {
  const filteredHeaders = headers[sheet];

  return (
    <section className="flex flex-col flex-grow gap-3">
      <h4 className="full-underlined-label">{label} Columns</h4>
      {ignoreResponse || filteredHeaders?.length > 1 ? (
        <ul className="flex flex-col flex-grow gap-1">
          {filteredHeaders?.map((col: string, colIndex: number) => {
            if (col === "--row_id--" || col === "") return null;
            const markedAsQuestion = markedAsQuestionCol[sheet] === colIndex;
            const markedAsAnswer = markedAsAnswerCol[sheet] === colIndex;
            return (
              <li
                key={colIndex}
                className="flex items-center gap-5 text-left font-semibold"
              >
                {colIndex}. {col}
                <article className="flex items-center gap-2">
                  <input
                    type="radio"
                    checked={markedAsQuestion}
                    onChange={() => {
                      setMarkedAsQuestionCol({
                        ...markedAsQuestionCol,
                        [sheet]: colIndex,
                      });
                      if (markedAsAnswerCol[sheet] === colIndex)
                        setMarkedAsAnswerCol({
                          ...markedAsAnswerCol,
                          [sheet]: -1,
                        });
                    }}
                    className="form-radio w-4 h-4 dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                  />
                  <label htmlFor="">Questions?</label>
                </article>
                {!ignoreResponse && (
                  <article className="flex items-center gap-2">
                    <input
                      type="radio"
                      checked={markedAsAnswer}
                      onChange={() => {
                        setMarkedAsAnswerCol({
                          ...markedAsAnswerCol,
                          [sheet]: colIndex,
                        });
                        if (markedAsQuestionCol[sheet] === colIndex)
                          setMarkedAsQuestionCol({
                            ...markedAsQuestionCol,
                            [sheet]: -1,
                          });
                      }}
                      className="form-radio w-4 h-4 dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                    />
                    <label htmlFor="">Answers?</label>
                  </article>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <span className="p-2 mt-2 break-words text-xs text-left dark:bg-inner border dark:border-error rounded-sm">
          Please upload a file with at least 2 columns
        </span>
      )}
    </section>
  );
};

export default Columns;
